@import url(https://use.fontawesome.com/releases/v6.1.2/css/all.css);
.container {
  width: 100%;
}

@media (min-width: 0px) {
  .container {
    max-width: 0px;
  }
}

@media (min-width: 600px) {
  .container {
    max-width: 600px;
  }
}

@media (min-width: 960px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1920px) {
  .container {
    max-width: 1920px;
  }
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-primary {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(5, 110, 157, var(--tw-bg-opacity)) !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-xl {
  border-radius: 0.75rem !important;
}

.rounded-3xl {
  border-radius: 1.5rem !important;
}

.border-none {
  border-style: none !important;
}

.border {
  border-width: 1px !important;
}

.border-b-2 {
  border-bottom-width: 2px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.block {
  display: block !important;
}

.inline-block {
  display: inline-block !important;
}

.inline {
  display: inline !important;
}

.flex {
  display: flex !important;
}

.inline-flex {
  display: inline-flex !important;
}

.table {
  display: table !important;
}

.grid {
  display: grid !important;
}

.hidden {
  display: none !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-col {
  flex-direction: column !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.items-start {
  align-items: flex-start !important;
}

.items-end {
  align-items: flex-end !important;
}

.items-center {
  align-items: center !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-around {
  justify-content: space-around !important;
}

.flex-shrink {
  flex-shrink: 1 !important;
}

.font-normal {
  font-weight: 400 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.font-bold {
  font-weight: 700 !important;
}

.h-4 {
  height: 1rem !important;
}

.h-5 {
  height: 1.25rem !important;
}

.h-8 {
  height: 2rem !important;
}

.h-9 {
  height: 2.25rem !important;
}

.h-12 {
  height: 3rem !important;
}

.h-36 {
  height: 9rem !important;
}

.h-40 {
  height: 10rem !important;
}

.h-60 {
  height: 15rem !important;
}

.h-96 {
  height: 24rem !important;
}

.h-full {
  height: 100% !important;
}

.text-xs {
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}

.text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

.text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.text-lg {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.text-4xl {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
}

.m-0 {
  margin: 0px !important;
}

.m-5 {
  margin: 1.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.my-3 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-8 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-10 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-12 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.mt-3 {
  margin-top: 0.75rem !important;
}

.mr-3 {
  margin-right: 0.75rem !important;
}

.mb-3 {
  margin-bottom: 0.75rem !important;
}

.ml-3 {
  margin-left: 0.75rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mr-4 {
  margin-right: 1rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.ml-4 {
  margin-left: 1rem !important;
}

.mt-5 {
  margin-top: 1.25rem !important;
}

.mr-5 {
  margin-right: 1.25rem !important;
}

.mb-5 {
  margin-bottom: 1.25rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.ml-6 {
  margin-left: 1.5rem !important;
}

.mt-8 {
  margin-top: 2rem !important;
}

.mr-8 {
  margin-right: 2rem !important;
}

.mb-8 {
  margin-bottom: 2rem !important;
}

.mt-10 {
  margin-top: 2.5rem !important;
}

.mr-10 {
  margin-right: 2.5rem !important;
}

.mb-10 {
  margin-bottom: 2.5rem !important;
}

.ml-10 {
  margin-left: 2.5rem !important;
}

.mt-12 {
  margin-top: 3rem !important;
}

.mb-12 {
  margin-bottom: 3rem !important;
}

.mt-16 {
  margin-top: 4rem !important;
}

.mb-16 {
  margin-bottom: 4rem !important;
}

.mr-20 {
  margin-right: 5rem !important;
}

.mb-32 {
  margin-bottom: 8rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.-mt-1 {
  margin-top: -0.25rem !important;
}

.-mb-3 {
  margin-bottom: -0.75rem !important;
}

.-mb-4 {
  margin-bottom: -1rem !important;
}

.max-w-xs {
  max-width: 20rem !important;
}

.max-w-lg {
  max-width: 32rem !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.p-0 {
  padding: 0px !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.py-28 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
}

.py-48 {
  padding-top: 12rem !important;
  padding-bottom: 12rem !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pb-3 {
  padding-bottom: 0.75rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pr-4 {
  padding-right: 1rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pt-5 {
  padding-top: 1.25rem !important;
}

.pb-6 {
  padding-bottom: 1.5rem !important;
}

.pl-6 {
  padding-left: 1.5rem !important;
}

.pt-8 {
  padding-top: 2rem !important;
}

.pb-8 {
  padding-bottom: 2rem !important;
}

.pt-20 {
  padding-top: 5rem !important;
}

.pb-32 {
  padding-bottom: 8rem !important;
}

.static {
  position: static !important;
}

.fixed {
  position: fixed !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.right-0 {
  right: 0px !important;
}

.right-2 {
  right: 0.5rem !important;
}

.resize {
  resize: both !important;
}

* {
  --tw-shadow: 0 0 #0000;
}

.shadow-none {
  --tw-shadow: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

* {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-black {
  --tw-text-opacity: 1 !important;
  color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
}

.text-white {
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}

.text-blue {
  --tw-text-opacity: 1 !important;
  color: rgba(5, 110, 157, var(--tw-text-opacity)) !important;
}

.text-primary {
  --tw-text-opacity: 1 !important;
  color: rgba(83, 95, 116, var(--tw-text-opacity)) !important;
}

.text-disabled {
  --tw-text-opacity: 1 !important;
  color: rgba(157, 165, 179, var(--tw-text-opacity)) !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.underline {
  text-decoration: underline !important;
}

.visible {
  visibility: visible !important;
}

.break-normal {
  overflow-wrap: normal !important;
  word-break: normal !important;
}

.break-all {
  word-break: break-all !important;
}

.w-6 {
  width: 1.5rem !important;
}

.w-16 {
  width: 4rem !important;
}

.w-24 {
  width: 6rem !important;
}

.w-36 {
  width: 9rem !important;
}

.w-48 {
  width: 12rem !important;
}

.w-64 {
  width: 16rem !important;
}

.w-96 {
  width: 24rem !important;
}

.w-1\/2 {
  width: 50% !important;
}

.w-2\/3 {
  width: 66.666667% !important;
}

.w-3\/4 {
  width: 75% !important;
}

.w-2\/5 {
  width: 40% !important;
}

.w-full {
  width: 100% !important;
}

.w-max {
  width: -webkit-max-content !important;
  width: max-content !important;
}

.transform {
  --tw-translate-x: 0 !important;
  --tw-translate-y: 0 !important;
  --tw-rotate: 0 !important;
  --tw-skew-x: 0 !important;
  --tw-skew-y: 0 !important;
  --tw-scale-x: 1 !important;
  --tw-scale-y: 1 !important;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

@keyframes fbPing {
  100% {
    transform: scale(1.05, 1.25);
    opacity: 0;
  }
}

.animate-fbPing {
  animation: fbPing .5s linear 5 !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:not(path) {
  revert: all;
}

@media (min-width: 1920px) {
}

@media (min-width: 1280px) {
}

@media (min-width: 960px) {
  .md\:h-72 {
    height: 18rem !important;
  }

  .md\:h-80 {
    height: 20rem !important;
  }

  .md\:px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
}

@media (min-width: 600px) {
}

@media (min-width: 0px) {
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.StripeElement {
  width: 100%;
  border: 1px solid #bbc3d1;
  border-radius: 8px;
  padding: 12px 10px;
  font-size: 14;
  font-family: Poppins;
  color: #535f74;
}

.StripeElement--focus {
  border-color: #056e9d;
  outline: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

/*!
 * Bootstrap Grid v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@-ms-viewport {
  width: device-width;
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.333333%;
  }

  .offset-sm-2 {
    margin-left: 16.666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.333333%;
  }

  .offset-sm-5 {
    margin-left: 41.666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.333333%;
  }

  .offset-sm-8 {
    margin-left: 66.666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.333333%;
  }

  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.333333%;
  }

  .offset-md-2 {
    margin-left: 16.666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.333333%;
  }

  .offset-md-5 {
    margin-left: 41.666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.333333%;
  }

  .offset-md-8 {
    margin-left: 66.666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.333333%;
  }

  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.333333%;
  }

  .offset-lg-2 {
    margin-left: 16.666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.333333%;
  }

  .offset-lg-5 {
    margin-left: 41.666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.333333%;
  }

  .offset-lg-8 {
    margin-left: 66.666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.333333%;
  }

  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.333333%;
  }

  .offset-xl-2 {
    margin-left: 16.666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.333333%;
  }

  .offset-xl-5 {
    margin-left: 41.666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.333333%;
  }

  .offset-xl-8 {
    margin-left: 66.666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.333333%;
  }

  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
/* EUI theme styles */

/* Vendor global styles */

/* Global Styles */

body,
html {
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
}

.header-font {
  font-family: Poppins, sans-serif;
}

/* Overriding styles */

.euiFieldText:focus {
  background-image: none;
  box-shadow: inset 0 0 0 1px #07c;
}

.euiBody-hasPortalContent {
  position: static !important;
  position: initial !important;
}

.euiFormLabel.euiFormLabel-isFocused {
  color: #1a1c21;
}

.euiPopover__arrow::before {
  border-color: transparent transparent #cce4f5 transparent !important;
}

.euiTourFooter {
  border: none !important;
  background: none !important;
}

.customFlyout .euiButtonIcon {
  display: none;
}

.customFlyout {
  .container-fluid {
    padding-top: 0 !important;
  }
  .css-2pd10y {
    padding-top: 0 !important;
  }
}

.MuiContainer-maxWidthLg {
  overflow-y: auto;
}

.euiModal__closeIcon {
  display: none !important;
}

.euiTableRow:hover {
  background-color: #e6f9f7;
}

.customFlyout {
  z-index: 3001 !important;
}

.btn-text {
  font-size: 16px !important;
  margin-right: 1rem;
}

label {
  font-size: 16px !important;
}

.euiFieldText {
  font-size: 16px !important;
  background-color: #fff;
  box-shadow: 0 0 transparent, inset 0 0 0 1px rgba(94, 100, 111, 1);
  max-width: 428px !important;
}

/* input {
  font-size: 16px !important;
  background-color: #fff;
  box-shadow: 0 0 transparent, inset 0 0 0 1px rgba(94, 100, 111, 1);
} */

.euiFormControlLayoutIcons .euiIcon[data-icon-type="warning"] {
  display: none;
}

.euiRadio .euiRadio__input ~ .euiRadio__label {
  z-index: 0;
}

.debt-icon {
  align-self: flex-end;
  padding-bottom: .75rem;
  svg {
    width: 20px !important;
    fill: #1a1c21
  }
  .euiButtonIcon {
    display: block !important;
  }
}

.euiIcon.css-h17o4q-euiIcon-m-danger {
  display: none;
}

.euiFormControlLayout {
  height: auto;
  max-width: 428px;
}

.btn-spacing {
  margin-right: 1rem;
}

.euiComboBox .euiComboBox__inputWrap {
  font-size: 16px !important;
  background-color: #fff !important;
  box-shadow: 0 0 transparent, inset 0 0 0 1px rgba(94, 100, 111, 1) !important;
  background-image: none !important;
}

.euiSuperSelectControl-isInvalid {
  border-color: 1px solid #bd271e;
  outline: none;
  box-shadow: 0 0 0 2px rgba(189, 39, 30, 1);
  background-image: none;
}

.euiComboBox.euiComboBox-isInvalid .euiComboBox__inputWrap {
  border: 1px solid #bd271e;
  outline: none;
  box-shadow: 0 0 0 2px rgba(189, 39, 30, 1);
}

.euiComboBox .euiComboBox-isOpen .euiComboBox_inputWrap {
  background-color: #fff !important;
  box-shadow: inset 0 0 0 2px rgba(0, 119, 204, 1) !important;
  background-image: none !important;
}

.euiFormHelpText {
  font-size: 16px !important;
  line-height: 1.5;
}

.input-size-child {
  flex: 1 1 auto;
}

.euiFormControlLayout--group {
  background-color: #5e646f !important;
}

.euiFormControlLayout--group:focus {
  border-color: #07c;
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 119, 204, 1);
}

/* MOBILE */

@media (max-width: 445px) {
  .continue-button {
    margin-left: 0 !important;
    color: #006BB8;
  }
}

@media (max-width: 365px) {
  .continue-button {
    margin-top: 1rem;
  }
}

@media (min-width: 576px) {
  .euiFormRow,
  .euiFormControlLayout,
  input {
    max-width: 428px !important;
  }

  .euiFormRow,
  .euiFormControlLayout {
    max-width: 428px !important;
  }

  .euiComboBox {
    max-width: 428px !important;
  }

  .euiComboBox .euiComboBox__inputWrap {
    max-width: 428px !important;
  }

  .css-3n67oa-euiPopover-EuiInputPopover {
    max-inline-size: 428px !important;
  }

  .euiSuperSelectControl {
    max-width: 428px !important;
  }

  .input-size-child input {
    width: 192px;
  }
}

.euiSuperSelectControl.euiSuperSelect--isOpen__button {
  background-color: white;
  background-image: none;
  background-size: 100% 100%;
  outline: none;
  box-shadow: inset 0 0 0 2px rgba(0, 119, 204, 1);
}

.euiSuperSelectControl:focus {
  background-image: none;
  box-shadow: inset 0 0 0 1px rgba(94, 100, 111, 1);
}

.euiSuperSelect__item:hover {
  text-decoration: none;
  background-color: rgba(0, 109, 228, 0.1);
}

.euiSuperSelect__item {
  font-size: 16px;
}

.euiSuperSelectControl {
  background-color: #fff;
  box-shadow: 0 0 transparent, inset 0 0 0 1px rgba(94, 100, 111, 1);
  font-size: 16px;
}

.euiFormControlLayout
  .euiFormControlLayout__childrenWrapper
  .euiFieldText:focus {
  border-color: #07c;
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 119, 204, 1);
}

.euiFieldText:invalid {
  border-color: #bd271e;
  outline: none;
  box-shadow: 0 0 0 2px rgba(189, 39, 30, 1);
  background-image: none;
  background-size: none;
}

button.euiButton.css-vdoabi-euiButtonDisplay-m-defaultMinWidth-isDisabled-fill-disabled {
  opacity: 0.4;
  color: white;
  background: #0077cc;
  font-size: 16px;
}

input:focus,
textarea:focus,
button:focus,
select:focus {
  outline: none !important;
}

option:focus {
  outline: none !important;
}

/* .euiFormErrorText {
  display: none;
} */

.input-size-small {
  max-width: 206px !important;
  background-color: #fff;
}

.input-size {
  max-width: 428px !important;
  font-family: "Inter", sans-serif;
}

.euiFormLabel {
  line-height: 24px;
}

.euiFormLabel.euiFormLabel-isInvalid {
  color: inherit;
}

.euiFormRow + .euiFormRow,
.euiFormRow + .euiButton {
  margin-top: 0;
}

.euiButton {
  font-size: 16px !important;
}

.euiButton .euiComboBoxPill--plainText {
  font-size: 16px;
}

.euiComboBoxPill--plainText {
  font-size: 16px;
}

.react-datepicker {
  display: none;
}

button[aria-selected="true"] {
  text-decoration: none !important;
}

button.euiFilterSelectItem {
  &:hover {
    background-color: rgba(0, 109, 228, 0.1);
    text-decoration: none;
  }
}

.euiContextMenuItem {
  border-top: 1px solid #f1f4fa;
}

span.euiComboBoxOption__content {
  font-size: 16px;
  line-height: 24px;
}

span.euiBadge.euiComboBoxOption__enterBadge.css-1d0tqsn-euiBadge-hollow {
  display: none;
}

.flyout-text {
  font-size: 12px;
  font-family: "Inter", sans-serif;
  color: #1a1c21;
}

.subscriptionCard {
  border: 1px solid #5e646f !important;
  padding: 16px;
  width: 202px;
  height: 132px;
  h3 {
    font-size: 22px;
    line-height: 20px;
    font-family: "Inter", sans-serif;
  }
  .price {
    font-size: 27px;
    font-family: "Inter", sans-serif;
    color: #343741;
  }
  .sub-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 15px;
    gap: 15px;
    .plan-text {
      font-size: 14px;
      font-family: "Inter", sans-serif;
      color: #343741;
    }
  }
}

.annual-sub {
  position: relative;
}

.subscriptionCard.recommended::after {
  content: "RECOMMENDED";
  position: absolute;
  top: -13px;
  right: 40px;
  background: #0077cc;
  color: white;
  font-size: 12px;
  font-weight: bold;
  font-family: "Inter", sans-serif;
  padding: 4px 12px;
  border-radius: 14px;
  z-index: 1;
}

.euiPanel.euiPanel--subdued.euiPanel--paddingMedium.euiSplitPanel__inner.css-1q2pk3l-euiPanel-none-m-subdued-isClickable-euiSplitPanelInner {
  background: none;
  padding-top: 8px;
  padding-left: 0;
}

.euiPanel.euiPanel--primary.euiPanel--paddingMedium.euiSplitPanel__inner.css-1wbcvob-euiPanel-none-m-primary-isClickable-euiSplitPanelInner {
  background: none;
  padding-top: 8px;
  padding-left: 0;
}

label.euiCheckableCard__label.css-a2fh8y-euiCheckableCard__label {
  padding-left: 0px;
  padding-top: 8px;
  padding-right: 0;
}

.euiPanel.euiPanel--plain.euiSplitPanel.euiCheckableCard.subscriptionCard.css-fbc5mb-euiPanel-m-plain-hasBorder-euiSplitPanelOuter-row-euiCheckableCard-isChecked {
  margin: 0;
  background-color: #e6f1fa;
  border: 1px solid #0077cc !important;
  box-shadow: 0 0 0 3px #0077cc;
}

.euiPanel.euiPanel--transparent.euiPanel--paddingMedium.euiSplitPanel__inner.css-1lwt1x1-euiPanel-grow-none-m-transparent-euiSplitPanelInner {
  padding-left: 0;
}

.billing-summary {
  max-width: 444px;
  border: none;
  border-radius: 4px;
  padding: 16px;
  background-color: #f1f4fa !important;
  box-shadow: none !important;
  font-family: "Inter", sans-serif;
  color: #1a1c21;
}

.billingSummary .euiHorizontalRule {
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Login-Registration Page */

.half {
  width: 50%;
  position: fixed;
  top: 0;
  overflow: auto;
  padding: 1rem;
  .term-condition {
    max-width: 428px;
    width: 100%;
    font-family: "Inter", sans-serif;
    color: #343741;
    a.term-link {
      color: #343741 !important;
      text-decoration: underline;
    }
  }
}

.info-container {
  left: 0;
  background-color: #e6f1fa;
  display: flex;
  align-items: center;
  justify-content: center;
  .info-content {
    text-align: center;
    ul {
      list-style-type: none;
      text-align: left;
      max-width: 600px;
      width: 100%;
    }
    li {
      display: flex;
      align-items: flex-start;
      color: #1a1c21;
      font-weight: 400;
      font-size: 22px;
      font-family: "Inter", sans-serif;
      line-height: 30px;
    }
    li::before {
      content: url(/static/media/check.f6b35c3a.svg);
      margin-right: 10px;
      margin-top: 5px;
    }
  }
}

.field-container {
  right: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 75px;
  .password-input {
    input {
      background-color: #fff;
    }
    .euiFieldPassword {
      padding-left: 12px !important;
    }
    .euiFormControlLayoutIcons {
      display: none;
    }
    .euiFormControlLayout--group .euiButtonIcon:not(:focus),
    .euiFormControlLayout--group
      [class*="euiButton"]:focus:not(:focus-visible) {
      background-color: #fbfcfd;
    }
    .euiButtonIcon {
      height: auto !important;
      height: initial !important;
      padding-left: 0;
      padding-right: 12px;
    }
    .euiFormControlLayout--group:focus-within {
      background-color: #07c !important;
      box-shadow: 0 0 0 1px rgba(0, 119, 204, 1);
    }
    .euiFieldPassword:focus {
      background-color: white !important;
      background-image: none !important;
    }
  }
}

.field-content {
  width: 100%;
  max-width: 444px;
  .desc-text {
    color: #1a1c21;
    font-size: 16px;
    font-family: "Inter", sans-serif;
  }
  .password-input {
    .euiFormRow__labelWrapper {
      display: block;
    }
    .euiFormLabel {
      display: flex;
      justify-content: space-between;
    }
    input {
      height: 40px;
    }
  }
  .pass-valid {
    margin-top: 4px;
    font-size: 16px;
    max-width: 428px;
    width: 100%;
    font-family: "Inter", sans-serif;
    color: #69707d;
    .euiIcon.css-h17o4q-euiIcon-m-danger {
      display: inline-block;
    }
    svg {
      margin-top: 2px;
    }
    .pass-align {
      display: flex;
      grid-gap: 8px;
      gap: 8px;
    }
  }
  .euiFieldText {
    max-width: 444px !important;
  }
  @media (min-width: 576px) {
    .euiFormRow,
    .euiFormControlLayout,
    input {
      max-width: 444px !important;
    }

    .euiFormRow,
    .euiFormControlLayout {
      max-width: 444px !important;
    }

    .euiComboBox {
      max-width: 444px !important;
    }

    .euiComboBox .euiComboBox__inputWrap {
      max-width: 444px !important;
    }

    .css-3n67oa-euiPopover-EuiInputPopover {
      max-inline-size: 444px !important;
    }

    .euiSuperSelectControl {
      max-width: 444px !important;
    }
  }
}

.mobile-logo {
  display: none;
}

.logo {
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 99999;
}

.login-button {
  width: 444px;
}

.login-main {
  .half {
    height: 100%;
  }
  .field-container {
    justify-content: center;
    padding-top: 0;
    margin-top: 0;
  }
}

.add-text {
  color: #1a1c21 !important;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  max-width: 428px;
  width: 100%;
  a {
    color: #1a1c21 !important;
    font-weight: 400 !important;
    text-decoration: underline;
  }
}

.new-paywall {
  position: absolute;
  top: 0;
  z-index: 99999;
  @media screen and (max-width: 992px){
    .logo {
      padding-left: 0 !important;
    }
  }
}

.password-input {
  .euiFormControlLayout--group .euiButtonIcon:not(:focus),
  .euiFormControlLayout--group [class*="euiButton"]:focus:not(:focus-visible) {
    border: none;
  }
  .euiFieldPassword:invalid {
    box-shadow: none;
    background-image: none;
  }
}

.euiComboBox__input {
  box-shadow: none;
}

.pass-link {
  font-weight: 400 !important;
}

.secondary-header {
  font-size: 23.6px !important;
}

.invalid .euiFormControlLayout.euiFormControlLayout--group {
  border-color: #bd271e;
  outline: none;
  box-shadow: 0 0 0 2px #bd271e;
  background-color: #bd271e !important;
  box-shadow: 0 0 0 1px #bd271e;
}

.email-error {
  max-width: 428px;
  width: 100%;
}

.curriculum {
  .field-container {
    justify-content: initial !important;
    .field-content {
      max-width: 445px;
    }
  }
  .header-mobile {
    font-size: 30px;
  }
  .term-condition {
    max-width: 428px;
    width: 100%;
    font-family: "Inter", sans-serif;
    color: #343741;
    a.term-link {
      color: #343741 !important;
      text-decoration: underline;
    }
  }
  .white-side {
    padding-top: 55px;
  }
  @media (max-width: 576px) {
    .euiFormRow {
      max-width: 428px;
    }

    .euiSuperSelectControl {
      max-width: 428px;
    }

    .euiPopover {
      max-inline-size: 428px;
    }
  }
}

.paywall {
  /* justify-content: initial !important; */
  height: 100%;
}

.header-mobile-lg {
  font-size: 30px !important;
}

.paywall-info {
  position: absolute;
  z-index: 9999;
  height: 100%;
}

.paywall-field {
  position: absolute;
  z-index: 9999;
  .logo {
    padding: 0;
  }
  @media screen and (max-width: 576px){
    .field-content {
      margin-top: 0 !important;
    }
  }
}

.paywall-logo {
  position: absolute;
  z-index: 9999;
}

@media (max-width: 992px) {
  .paywall {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .billing-summary {
    display: none !important;
  }

  .half,
  .info-container,
  .field-container {
    width: 100%;
    position: relative;
  }

  .info-container {
    display: none;
  }

  .mobile-logo {
    display: block;
  }

  .desktop-logo {
    display: none;
  }

  .field-container {
    padding-top: 50px;
    .feat-list-mobile {
      ul {
        list-style-type: none;
        max-width: 600px;
        width: 100%;
        margin: 0;
        padding: 0;
      }
      li {
        display: flex;
        align-items: flex-start;
        color: #1a1c21;
        font-weight: 400;
        font-size: 16px;
        font-family: "Inter", sans-serif;
        line-height: 22px;
        margin-bottom: 5px;
      }
      li::before {
        content: url(/static/media/mobile_check.6319f301.svg);
        margin-right: 10px;
        margin-top: 2px;
      }
    }
  }

  .login-main {
    .field-container {
      /* justify-content: initial !important; */
    }
    .field-content {
      margin-top: 80px;
      text-align: center;
      .login-title {
        text-align: left;
      }
    }
    .forgot-password {
      text-align: left;
    }
  }

  .logo {
    padding-top: 1rem;
    padding-left: 1rem;
    position: static;
    position: initial;
  }
}

@media screen and (max-width: 576px) {
  .field-container {
    align-items: flex-start;
    .field-content {
      margin-top: 0;
    }
  }

  .euiFormRow,
  .euiFormControlLayout {
    max-width: 428px !important;
  }
}

@media screen and (max-width: 500px) {
  .subscriptionCard {
    width: 163px;
    height: 144px;
    .sub-content {
      grid-gap: 12px;
      gap: 12px;
    }
  }

  .subscriptionCard.recommended::after {
    right: 25px;
  }
}

.new-container {
  display: flex;
  min-height: 100vh; /* Ensures at least full viewport height */
  width: 100%;
}

.blue-side,
.white-side {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 100vh;
}

.blue-side {
  color: white;
  align-items: flex-start;
}

.white-side {
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 160px;
  justify-content: flex-start;
}

.new-logo {
  position: absolute;
  top: 52px;
  left: 60px;
  width: 161px;
  height: 39px;
}

.blue-content {
  width: 100%;
  padding-top: 171px; /* 52px logo top + 39px logo height + 120px gap */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.account-form {
  width: 100%;
  max-width: 444px;
  display: flex;
  flex-direction: column;
}

.form-header {
  width: 100%;
  margin-bottom: 10px;
}

@media (max-width: 992px) {
  .blue-side {
    display: none;
  }

  .white-side {
    flex: 1 1;
    padding-top: 55px !important;
  }

  .header-font-reg {
    line-height: 28px !important;
  }
}

@media (max-width: 576px) {
  .white-side {
    padding-top: 32px !important;
    .form-header {
      margin-bottom: 0px;
    }
  }
}

/* Profile Builder 2.0 CSS */

/* EUI theme styles */

/* Vendor global styles */

/* Global Styles */

body, html {
  font-family: 'Inter', sans-serif !important;
  margin: 0;
  padding: 0;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
  background-color: #fff !important;
  line-height: normal !important;
  line-height: initial !important;
  color: #343741 !important;
}

.header-font {
  font-family: Poppins, sans-serif;
}

/* Overriding styles */

.euiFieldText:focus {
  background-image: none;
  box-shadow: inset 0 0 0 1px #07C;
}

.euiBody-hasPortalContent {
  position: static !important;
  position: initial !important;
}

.euiFormLabel.euiFormLabel-isFocused {
  color: #1a1c21;
}

.euiPopover__arrow::before {
  border-color: transparent transparent #ccE4F5 transparent !important;
}

.euiTourFooter {
  border: none !important;
  background: none !important;
}

.customFlyout .euiButtonIcon {
  display: none;
}

.euiModal__closeIcon {
  display: none !important;
}

.euiTableRow:hover {
  background-color: #E6F9F7;
}

.customFlyout {
  z-index: 3001 !important;
}

.btn-text {
  font-size: 16px !important;
  margin-right: 1rem;
}

label {
  font-size: 16px !important;
}

.euiFieldText {
  font-size: 16px !important;
  background-color: #fff;
  box-shadow: 0 0 transparent, inset 0 0 0 1px rgba(94, 100, 111, 1);
}

/* input {
  font-size: 16px !important;
  background-color: #fff;
  box-shadow: 0 0 transparent, inset 0 0 0 1px rgba(94, 100, 111, 1);
} */

.euiFormControlLayoutIcons .euiIcon[data-icon-type="warning"] {
  display: none;
}

.euiIcon.css-h17o4q-euiIcon-m-danger {
  display: none;
}

button.euiButton.css-8utmkn-euiButtonDisplay-m-defaultMinWidth-fill-primary {
  font-size: 16px
}

.euiFormControlLayout {
  height: auto;
}

.btn-spacing {
  margin-right: 1rem;
}

.euiComboBox .euiComboBox__inputWrap {
  font-size: 16px !important;
  background-color: #fff !important;
  box-shadow: 0 0 transparent, inset 0 0 0 1px rgba(94, 100, 111, 1) !important;
  background-image: none !important;
}

.euiComboBox .euiComboBox-isOpen .euiComboBox_inputWrap {
  background-color: #fff !important;
  box-shadow: inset 0 0 0 2px rgba(0, 119, 204, 1) !important;
  background-image: none !important;
}

.euiFormHelpText {
  font-size: 16px !important;
  line-height: 1.5;
}

.input-size-child {
  flex: 1 1 auto;
}

.euiFormControlLayout--group {
  background-color: #5e646f !important;
}

.euiFormControlLayout--group:focus {
  border-color: #07C;
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 119, 204, 1)
}

.euiComboBox {
  max-width: 428px;
}

/* MOBILE */

@media (min-width: 576px) {
  .euiFormRow, .euiFormControlLayout, input {
    max-width: 428px !important;
  }

  .euiFormRow, .euiFormControlLayout {
    max-width: 428px !important;
  }

  .euiComboBox {
    max-width: 428px !important;
  }

  .euiComboBox .euiComboBox__inputWrap {
    max-width: 428px !important;
  }

  .css-3n67oa-euiPopover-EuiInputPopover {
    max-inline-size: 428px !important;
  }

  .euiSuperSelectControl {
    max-width: 428px !important;
  }

  .input-size-child input {
    width: 192px;
  }
}

.euiSuperSelectControl.euiSuperSelect--isOpen__button {
  background-color: white;
  background-image: none;
  background-size: 100% 100%;
  outline: none;
  box-shadow: inset 0 0 0 2px rgba(0, 119, 204, 1);
}

.euiSuperSelectControl:focus {
  background-image: none;
  box-shadow: inset 0 0 0 1px rgba(94, 100, 111, 1);
}

.euiSuperSelect__item:hover {
  text-decoration: none;
  background-color: rgba(0, 109, 228, 0.1);
}

.euiSuperSelect__item {
  font-size: 16px;
}

.euiSuperSelectControl {
  background-color: #fff;
  box-shadow: 0 0 transparent, inset 0 0 0 1px rgba(94, 100, 111, 1);
  font-size: 16px;
}

.euiFormControlLayout .euiFormControlLayout__childrenWrapper .euiFieldText:focus {
  border-color: #07C;
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 119, 204, 1)
}

.euiFieldText:invalid {
  border-color: #BD271E;
  outline: none;
  box-shadow: 0 0 0 2px rgba(189, 39, 30, 1);
  background-image: none;
  background-size: none;
}

button.euiButton.css-vdoabi-euiButtonDisplay-m-defaultMinWidth-isDisabled-fill-disabled {
  opacity: .4;
  color: white;
  background: #0077cc;
  font-size: 16px;
}

input:focus, 
textarea:focus, 
button:focus, 
select:focus {
  outline: none !important;
}

option:focus {
  outline: none !important;
}

.profile-builder .euiFormErrorText {
  display: none;
}

.input-size-small {
  max-width: 206px !important;
  background-color: #fff;
}

.input-size {
  max-width: 428px !important;
}

.euiFormLabel {
  line-height: 24px;
  margin-bottom: 6px;
}

.euiFormLabel.euiFormLabel-isInvalid {
  color: inherit;
}

.euiFormRow + .euiFormRow, .euiFormRow + .euiButton {
  margin-top: 0;
}

.euiButton {
  font-size: 16px;
}

.euiButton .euiComboBoxPill--plainText {
  font-size: 16px;
}

.euiComboBoxPill--plainText {
  font-size: 16px;
}

.react-datepicker {
  display: none;
}

button[aria-selected="true"] {
  text-decoration: none !important;
}

button.euiFilterSelectItem {
  &:hover {
    background-color: rgba(0, 109, 228, 0.1);
    text-decoration: none;
  }
}

.euiContextMenuItem {
  border-top: 1px solid #F1F4FA;
}

span.euiComboBoxOption__content {
  font-size: 16px;
  line-height: 24px;
}

span.euiBadge.euiComboBoxOption__enterBadge.css-1d0tqsn-euiBadge-hollow {
  display: none;
}
.side-nav {
  width: 224px;
  min-height: 100vh;
  padding: 0 !important;
  ul.euiListGroup {
    padding: 0;
  }
  .logo-container {
    padding: 30px 10px 30px 15px;
  }
  h3,
  span.euiListGroupItem__label {
    font-size: 14px;
    font-family: Inter, sans-serif;
    font-weight: 500;
  }
  .euiAccordion.euiCollapsibleNavGroup {
    border: none;
  }
  .euiAccordion__button:focus {
    text-decoration: none;
  }
  .euiCollapsibleNavGroup__heading:hover {
    text-decoration: none;
  }
  .euiAccordion__triggerWrapper,
  button.euiListGroupItem__button {
    padding: 1rem 10px 1rem 15px !important;
    &:hover,
    &:active,
    &:focus {
      background-color: #e6f1fa;
      text-decoration: none;
    }
  }
  button.euiListGroupItem__button.css-14avnfv-euiListGroupItem__inner-m-text-isActive-isClickable {
    background-color: #e6f1fa;
    color: #006bb8;
    text-decoration: none;
    path {
      fill: #006bb8;
    }
  }
  .single-nav-item {
    .euiListGroupItem__label {
      padding-left: 10px !important;
    }
  }
  .nested-nav-item {
    .euiListGroupItem__label {
      padding-left: 30px !important;
    }
  }
  .euiCollapsibleNavGroup__children {
    padding: 0;
  }
  .euiButtonIcon {
    margin: 0;
    width: 15px;
    height: 15px;
  }
  .euiListGroupItem {
    border-radius: 0;
    &.css-1n8d9is-euiListGroupItem__inner-m-text-isClickable {
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
}

.popout-nav {
  width: 276px;
  min-height: 100vh;
  padding: 0 !important;
  ul.euiListGroup {
    padding: 0;
  }
  .euiPanel {
    padding: 0;
  }
  .popout-nav-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    svg {
      cursor: pointer;
    }
  }
  .euiFlyout__closeButton {
    display: none;
  }
  .logo-container {
    padding: 30px 10px 30px 15px;
  }
  h3,
  span.euiListGroupItem__label {
    font-size: 14px;
    font-family: Inter, sans-serif;
    font-weight: 500;
  }
  .euiAccordion.euiCollapsibleNavGroup {
    border: none;
  }
  .euiAccordion__button:focus {
    text-decoration: none;
  }
  .euiCollapsibleNavGroup__heading:hover {
    text-decoration: none;
  }
  .euiAccordion__triggerWrapper,
  button.euiListGroupItem__button {
    padding: 1rem 10px 1rem 15px !important;
    &:hover,
    &:active,
    &:focus {
      background-color: #e6f1fa;
      text-decoration: none;
    }
  }
  button.euiListGroupItem__button.css-14avnfv-euiListGroupItem__inner-m-text-isActive-isClickable {
    background-color: #e6f1fa;
    color: #006bb8;
    text-decoration: none;
    path {
      fill: #006bb8;
    }
  }
  .single-nav-item {
    .euiListGroupItem__label {
      padding-left: 10px !important;
    }
  }
  .nested-nav-item {
    .euiListGroupItem__label {
      padding-left: 30px !important;
    }
  }
  .euiCollapsibleNavGroup__children {
    padding: 0;
  }
  .euiButtonIcon {
    margin: 0;
    width: 15px;
    height: 15px;
  }
  .euiListGroupItem {
    border-radius: 0;
    &.css-1n8d9is-euiListGroupItem__inner-m-text-isClickable {
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
}

.navheader {
  width: 100%;
  min-height: 0;
  max-height: 130px;
  display: block;
}

.main-nav {
  background-color: #f7f8fc !important;
}

.euiHeader.main-nav.css-sqbryr-euiHeader-static-default {
  background-color: #f7f8fc !important;
}

.dashboard {
  flex-direction: row !important;
}

.main-container {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  object-fit: contain;
  background-color: #f7f8fc;
  flex-flow: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  overflow: auto;
}

.euiPopover__arrow::before {
  border-color: transparent transparent #fff transparent !important;
}

.nav-header {
  background-color: #f7f8fc !important;
  min-height: 60px;
  button.euiButtonEmpty.euiHeaderSectionItemButton {
    block-size: 0;
    min-inline-size: 0;
  }
  .main-nav {
    background-color: #f7f8fc;
    /* padding-left: 16px;
    padding-right: 16px; */
    .logo-container {
      background-color: #f7f8fc;
    }
    .hamburger-menu {
      width: 20px;
      height: 20px;
      margin-right: 1rem;
      fill: #056e9d;
      cursor: pointer;
    }
    .hidden-logo {
      visibility: hidden;
    }
  }
}

.profile-popover {
  font-family: Inter;
  font-size: 14px;
  .euiPanel {
    padding: 0;
  }
  width: 193px;
  padding: 1rem 0;
  ul {
    padding: 0;
  }
}

.main-content {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  background-color: #f7f8fc;
  flex-flow: wrap;
  align-content: stretch;
  justify-content: space-between;
  align-self: auto;
  align-items: stretch;
  width: auto;
  display: flex;
  padding-bottom: 16px;
}

.card {
  flex: 0 1 auto;
  justify-content: flex-start;
  align-self: auto;
  width: 100%;
  min-width: auto;
  max-width: 49%;
  max-height: 322px;
  min-height: 322px;
  display: block;
}

.fw-card {
  max-width: 100%;
  max-height: 100%;
}

.nav-callout {
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  p.euiTitle {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
  }
  svg {
    width: 24px;
    height: 24px;
    fill: #006bb8;
    margin: 0;
  }
  .euiText svg {
    fill: #231f20;
  }
  p {
    color: #231f20;
  }
}

@media screen and (max-width: 991px) {
  .side-nav {
    display: none;
  }

  .main-container {
    margin-top: 60px;
    padding-top: 16px;
  }

  .nav-header {
    background-color: #fff !important;
    min-height: auto;
    max-height: none;
    position: fixed;
    top: 0%;
    bottom: auto;
    left: 0%;
    right: 0%;
    z-index: 1000;
    .main-nav {
      min-height: 60px;
      background-color: #fff !important;
      padding: 1rem;
      .logo-container {
        background-color: #fff;
      }
    }
  }

  .euiHeader.main-nav.css-sqbryr-euiHeader-static-default {
    background-color: #fff;
  }

  .main-content {
    flex-flow: wrap;
    justify-content: space-between;
    align-self: auto;
    /* margin-top: auto; */
  }

  .card {
    max-width: none;
  }

  .nav-callout {
    background-color: #e6f1fa;
    min-height: 60px;
  }
}

.fp-context-menu {
  width: 128px !important;
}

.fp-general {
  .fp-header-text {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    line-height: 32px;
  }
}

.fp-page-zero {
  .fp-content {
    max-width: 90%;
    p {
      font-family: Inter, sans-serif;
      font-weight: 400;
      line-height: 24px;
      font-size: 16px;
      color: #000;
    }
  }
  .fp-card {
    width: 385px;
    height: 352px;
    border: 2px dashed #e5e5e5;
  }
}

.fp-page-list {
  width: 100%;
  .css-4ld6o8-euiCard__children {
    -webkit-margin-before: 0px !important;
    margin-block-start: 0px !important;
  }
  .fp-flex-group {
    align-items: center;
  }
  .fp-flex-end {
    align-items: flex-end;
  }
  .fp-list-desc {
    font-family: Inter, sans-serif;
    font-weight: 400;
    line-height: 24px;
    font-size: 16px;
    color: #000;
  }
  .fp-card-list-container {
    padding: 1rem 0;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .fp-card-list-container::-webkit-scrollbar {
    display: none;
  }
  .fp-card-list {
    flex: 0 0 auto;
    margin-right: 16px;
  }
  .fp-card-list:last-child {
    margin-right: 0;
  }
  .fp-card {
    width: 385px;
    height: 385px;
  }
  .fp-card-empty {
    position: relative;
    cursor: pointer;
    border: 2px dashed #e5e5e5;
  }
  .fp-card-empty .euiCard__title {
    display: none;
  }
  .fp-card-empty-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    svg {
      width: 54px;
      height: 54px;
      fill: #98a2b3;
    }
  }
  .fp-progress-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  @media (max-width: 989px) {
    .fp-card-list-container {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
}

.nw-page {
  width: 100%;
  .container-fluid {
    padding-top: 0 !important;
  }
  .css-2pd10y {
    padding-top: 0 !important;
  }
  .nw-header-text {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    line-height: 32px;
  }
  .hc-desc-text {
    h1 {
      font-family: Poppins, sans-serif;
      font-weight: 600;
      line-height: 32px;
      font-size: 20px;
    }
  }
  .hc-desc {
    p {
      font-family: Inter, sans-serif;
      font-weight: 400;
      line-height: 24px;
      font-size: 16px;
    }
  }
  .euiTabs {
    max-inline-size: -webkit-fit-content;
    max-inline-size: -moz-fit-content;
    max-inline-size: fit-content;
  }
  .nw-tab {
    span {
      font-family: Inter, sans-serif;
      font-size: 16px;
    }
  }
  .nw-secondary-text {
    p,
    ul {
      font-family: Inter, sans-serif;
      font-weight: 400;
      line-height: 20px;
      font-size: 16px;
      li {
        padding-bottom: 8px;
      }
    }
  }
  .nw-card {
    .css-4ld6o8-euiCard__children {
      -webkit-margin-before: 0;
      margin-block-start: 0;
    }
    .nw-card-title {
      text-align: left;
      line-height: 32px !important;
      font-size: 20px !important;
      font-weight: 600 !important;
      font-family: "Poppins", sans-serif;
      margin-bottom: 16px;
    }
  }
  .nw-bar-chart {
    .recharts-cartesian-grid-vertical {
      line {
        display: none;
      }
    }
  }
  .euiTableRow-isExpandedRow {
    padding: 0;
  }
  img.euiIcon.euiButtonIcon__icon.css-e8xmlg-euiIcon-m-inherit {
    -webkit-margin-after: 0;
    margin-block-end: 0;
  }
  thead {
    .euiTableCellContent__text {
      font-family: Inter, sans-serif;
      font-weight: 600;
      line-height: 20px;
      font-size: 16px;
    }
  }
  .total-row {
    .euiText {
      font-family: Inter, sans-serif;
      font-weight: 600;
      line-height: 24px;
      font-size: 16px;
    }
  }
  .nw-add-account-button {
    span {
      color: #343741;
    }
  }
  @media screen and (max-width: 768px) {
    .euiTabs {
      grid-gap: 12px !important;
      gap: 12px !important;
    }

    .hc-desc-text {
      h1 {
        font-size: 18px;
      }
    }
  }
}

.acct-page {
  width: 100%;
  .acct-header-text {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    line-height: 32px;
  }
  .acct-flex-group {
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .acct-link-group {
    max-width: 450px;
    display: flex;
    flex-wrap: nowrap;
  }
  .acct-flex-end {
    align-items: flex-end;
  }
  .align-center {
    align-items: center;
  }
  .justify-center {
    justify-content: center;
  }
  .acct-list-header {
    font-family: Inter, sans-serif;
    font-weight: 400;
    line-height: 20px;
    font-size: 14px;
  }
  .acct-list-totals {
    font-family: Inter, sans-serif;
    font-weight: bold;
    line-height: 32px;
    font-size: 27px;
  }
  .euiTabs {
    max-inline-size: -webkit-fit-content;
    max-inline-size: -moz-fit-content;
    max-inline-size: fit-content;
  }
  .acct-tab {
    span {
      font-family: Inter, sans-serif;
      font-size: 16px;
    }
  }
  .table-styles {
    tbody {
      font-size: 16px;
      line-height: 24px;
    }
    th {
      .euiTableCellContent__text {
        font-family: Inter, sans-serif;
        line-height: 24px;
        font-size: 16px;
      }
    }
    .euiTableRow-isExpandedRow {
      .euiTableCellContent {
        padding: 0;
      }
    }
    .expanded-rows {
      th .euiTableCellContent {
        padding: 8px !important;
        background-color: #fafbfd;
      }
      .euiTableRowCell {
        padding: 8px !important;
        background-color: #fafbfd;
      }
    }
  }
  .acct-total-group {
    grid-gap: 100px;
    gap: 100px;
  }
  @media (max-width: 768px) {
    .acct-flex-group {
      grid-gap: 0;
      gap: 0;
    }

    .acct-link-group {
      max-width: none;
    }

    .acct-flex-end {
      align-items: center;
      justify-content: space-between;
    }

    .acct-no-wrap {
      flex-wrap: nowrap;
    }

    .acct-list-totals {
      font-size: 16px;
    }

    .acct-total-group {
      grid-gap: 16px;
      gap: 16px;
    }

    .acct-flex-start {
      align-items: flex-start;
    }
  }
}

.acct-main {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

.customPopoverPanel {
  padding: 0 !important;
}

.lp-sidebar {
  .lp-dropdown {
    color: #343741;
    font-size: 16px;
    line-height: 24px;
    font-family: "Inter", sans-serif;
    svg {
      fill: #343741;
      width: 16px;
    }
  }
  .lp-sidebar-item {
    font-size: 16px;
    line-height: 24px;
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .lp-sidebar-item:hover {
    background-color: #e6f1fa;
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .active {
    background-color: #e6f1fa;
    font-weight: 600;
    color: #006bb8;
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .lp-secondary-text {
    font-size: 14px;
    line-height: 24px;
  }
}

.table-accounts {
  .euiTableCellContent__text {
    font-size: 16px;
    line-height: 24px;
  }
}

.error-provider {
  background-color: #F8E9E9;
  color: #BD271E;
}

.error-provider .lp-sidebar-item-name,
.error-provider .lp-sidebar-item-accounts {
  color: #BD271E;
  font-weight: 600;
}

.error-provider:hover {
  background-color: #F8E9E9 !important;
  color: #BD271E;
}

.error-provider.active {
  background-color: #F8E9E9;
  color: #BD271E;
}

.ai-main {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ai-plan {
  background-color: #f7f8fc;
  width: 100%;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
}

.ai-content-container {
  display: flex;
  flex: 1 1;
  overflow: hidden;
}

.ai-side-nav-mobile {
  background-color: #fff;
  width: 273px;
  max-width: 273px;
  max-height: 100vh;
  display: none;
  padding: 8px 16px;
  text-align: center;
  .section-label {
    svg {
      inline-size: 16px !important;
      block-size: 16px !important;
      fill: #006bb8;
      margin-left: 8px;
    }
    &:hover {
      cursor: pointer;
    }
    .section-label-title {
      font-size: 18px;
      font-family: "Poppins";
      font-weight: 600;
      line-height: 20px;
      display: block;
    }
    .secondary-label-text {
      font-family: "Poppins", sans-serif;
      font-size: 14px !important;
      line-height: 32px !important;
      margin: 0;
      color: #69707d;
      font-weight: 400;
    }
  }
  .mobile-nav-container {
    max-width: 280px;
    min-width: 280px;
    max-height: 80vh;
    margin-top: 1rem;
    text-align: left;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    overflow-y: auto;
  }
}

.context-menu {
  width: 120px;
}

.ai-content {
  mix-blend-mode: normal;
  background-color: #fff;
  width: 100%;
  margin: 16px;
  padding: 16px;
  flex: 1 1;
  overflow-y: auto;
}

.ai-footer-nav {
  padding: 16px;
  background: #fff;
  border-top: 1px solid #d3dae6;
  height: 72px;
  flex-shrink: 0;
  .euiFlexGroup {
    flex-wrap: nowrap;
  }
  .euiFlexItem {
    flex: 0 1 auto;
  }
  .danger-sec-btn {
    color: #b4251d;
    border: 1px solid #b4251d;
  }
}

.ai-side-nav-desk {
  padding: 16px;
  background-color: #fff;
  width: 273px;
  max-width: 273px;
  display: block;
  overflow-y: scroll;
}

/* SIDE-BAR STYLES */

.side-bar-wrapper {
  .side-bar-header {
    h2 {
      font-family: "Poppins", sans-serif;
      font-weight: bold !important;
      font-size: 22px !important;
      line-height: 40px !important;
      margin: 0;
      -webkit-margin-after: 0 !important;
      margin-block-end: 0 !important;
    }
  }
  .timeline-wrapper {
    position: relative;
  }
  .progress-bar {
    position: absolute;
    left: 10px;
    width: 4px;
    background: #e3e3e3;
    z-index: 1;
  }
  .progress-bar-filled {
    background: #0073e6;
    width: 100%;
    transition: height 0.3s ease;
    z-index: 2;
  }
  .timeline {
    padding: 0;
    margin: 0;
    position: relative;
    z-index: 3;
    li {
      cursor: pointer;
      color: #69707d;
      .euiText {
        font-size: 16px;
        font-family: "Inter", sans-serif;
        line-height: 24px;
      }
    }
  }
  .step-number {
    background-color: #0073e6;
  }
  .step-number,
  .euiIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    inline-size: 24px;
    block-size: 24px !important;
    border-radius: 50%;
    color: #fff;
    font-family: "Inter", sans-serif !important;
    font-size: 14px !important;
    font-weight: 600;
    margin: 0;
  }
  .euiIcon {
    color: #d3dae6;
  }
  .section-item {
    display: flex;
    align-items: center;
    padding: 0;
    cursor: default;
  }
  .css-xiu9el-euiTimeline-xl
    [class*="euiTimelineItem-"]:not(:last-child)
    > [class*="euiTimelineItemIcon-"]::before {
    block-size: auto !important;
  }
  .progress-bar-container {
    position: absolute;
    left: 10px;
    width: 4px;
    background: #e3e3e3;
    z-index: 1;
    height: 100%;
  }
  .timeline-step {
    display: flex;
    align-items: center;
  }
  .active-section-label {
    color: #343741 !important;
  }
  .visited-step .euiText {
    font-weight: 600;
    color: #343741 !important;
  }
  .visited-step-icon circle {
    fill: #0073e6;
    transition: fill 0.3s ease;
  }
}

/* PLANBUILDER CONTENT STYLES */

.ai-flex-content {
  flex-wrap: nowrap !important;
  .euiFlexItem {
    flex: 1 1 auto;
    flex-basis: auto !important;
    inline-size: auto !important;
  }
}

.ai-content-title {
  line-height: 32px !important;
  font-size: 22px !important;
  font-weight: 600 !important;
  font-family: "Poppins", sans-serif;
  margin: 0;
  .euiFlexItem {
    flex: 0 1 auto;
    svg {
      inline-size: 18px !important;
      block-size: 18px !important;
      fill: #006bb8;
    }
  }
}

.ai-card {
  .css-4ld6o8-euiCard__children {
    -webkit-margin-before: 0;
    margin-block-start: 0;
  }
  .ai-card-title {
    text-align: left;
    line-height: 32px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    font-family: "Poppins", sans-serif;
    margin-bottom: 16px;
  }
}

.ai-secondary-text {
  font-family: "Inter", sans-serif;
  font-size: 16px !important;
  line-height: 32px !important;
  margin: 0;
  font-weight: 400;
}

.flex-align {
  align-items: end;
}

.table-border {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  tr:last-child td {
    border-bottom: none;
  }
}

.table-text {
  .euiTableCellContent__text {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    line-height: 24px;
  }
}

.table-container {
  overflow-y: auto;
  width: 100%;
  @media (min-width: 983px) {
    max-width: 983px;
  }
}

.full-width-ai {
  max-width: 100% !important;
  .euiFormRow__fieldWrapper {
    max-width: 460px !important;
  }
}

/* REVIEW SECTION STYLES */

.completed-section-icon {
  fill: #00bfb3 !important;
}

.pencil-icon {
  border-radius: 0 !important;
  inline-size: 16px !important;
}

.header-style-review {
  font-weight: 600;
}

.content-row {
  .euiTableCellContent {
    padding-left: 16px;
  }
}

.stacked-table {
  max-height: 200px;
}

.review-table {
  .euiTableCellContent__text {
    font-size: 16px;
  }
  .euiTableCellContent {
    font-size: 16px;
    line-height: 24px;
  }
  .euiTableHeaderCell span {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
}

.expanded-table {
  thead {
    display: none;
  }
  .euiTableCellContent__text {
    font-size: 16px;
    font-weight: 600;
  }
}

.ai-table {
  .euiTableCellContent__text {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
}

.tabs-summary {
  .euiButtonGroupButton {
    background-color: #fbfcfd;
    border: 1px solid #cecece;
  }
  .euiButtonGroupButton-isSelected {
    background-color: #69707d;
  }
  span {
    font-size: 14px;
    font-family: "Inter", sans-serif;
  }
}

.no-wrap {
  flex-wrap: nowrap !important;
}

.align-l {
  align-items: flex-start !important;
}

.align-r {
  align-items: flex-end !important;
}

.flex-single {
  flex: 1 0 auto !important;
}

.mh-3 {
  min-height: 300px !important;
}

.custom-header {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.ai-header-content {
  font-weight: 600;
  font-size: 16px;
}

.ai-sub-header {
  padding-left: 26.5px;
  font-weight: 400;
}

.ai-sub-header-np {
  font-weight: 400;
}

.ai-sub-content {
  font-size: 16px;
}

/* MOBILE STYLES */

.mobile-content-style {
  font-size: 14px !important;
}

@media screen and (max-width: 991px) {
  .ai-side-nav-mobile {
    z-index: 999;
    background-color: #fff;
    width: 100%;
    max-width: none;
    height: 60px;
    max-height: 60px;
    display: block;
    position: fixed;
    .ai-mobile-nav-header {
      flex-wrap: nowrap;
      align-items: center;
      .ai-mobile-nav-item {
        flex: 1 1;
        svg {
          fill: #343741;
        }
      }
    }
  }

  .ai-content {
    margin-top: 76px;
  }

  .ai-side-nav-desk {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .ai-content {
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 1415px) {
  .ai-table {
    margin-left: 0;
    margin-right: 0;
  }
}

.eg-table {
  .euiTableCellContent {
    font-size: 16px;
    line-height: 24px;
    font-family: "Inter", sans-serif;
  }
  .euiTableHeaderCell span {
    font-size: 16px;
    line-height: 24px;
  }
  table {
    width: 100%;
    table-layout: auto;
    @media (max-width: 998px) {
      table-layout: fixed;

      .euiTableCellContent--truncateText {
        white-space: wrap !important;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .euiTableHeaderCell:first-child {
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        background-color: #fff;
        z-index: 2;
      }

      .euiTableHeaderCell:first-child:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 5px;
        background: linear-gradient(
          to right,
          rgba(206, 206, 206, 1),
          rgba(255, 255, 255, 0)
        );
      }

      .euiTableRow > .euiTableRowCell:first-child {
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        background-color: #fff;
        z-index: 2;
      }

      .euiTableRow > .euiTableRowCell:first-child:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 5px;
        background: linear-gradient(
          to right,
          rgba(206, 206, 206, 1),
          rgba(255, 255, 255, 0)
        );
      }

      th:nth-child(1),
      td:nth-child(1) {
        width: 200px;
      }

      th:nth-child(2),
      td:nth-child(2) {
        width: 200px;
      }

      th:nth-child(3),
      td:nth-child(3) {
        width: 200px;
      }

      th:nth-child(4),
      td:nth-child(4) {
        width: 200px;
      }

      th:nth-child(5),
      td:nth-child(5) {
        width: 200px;
      }

      th:nth-child(6),
      td:nth-child(6) {
        width: 260px;
      }
    }
  }
  .progress-section {
    grid-gap: 10px !important;
    gap: 10px !important;
    justify-content: center !important;
    align-items: center !important;
    .progress-bar {
      width: 174px;
      height: 8px;
    }
    .progress-text {
      align-items: flex-start;
      .euiText {
        font-size: 12px;
      }
    }
  }
  .drop-down-row {
    .cell-overflow {
      div {
        padding-left: 0px !important;
      }
    }
    .euiFlexGroup {
      grid-gap: 16px;
      gap: 16px;
    }
  }
  .child-row {
    background-color: #f7f8fc;
  }
  .cell-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .completed-link {
    font-size: 16px;
    font-family: "Inter", sans-serif;
    line-height: 24px;
  }
}

.tx-page {
  width: 100%;
  .acct-header-text {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    line-height: 32px;
  }
  .tx-flex-group {
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .tx-link-group {
    width: 450px;
    button {
      font-size: 16px;
    }
  }
  .tx-flex-end {
    align-items: flex-end;
  }
  .align-center {
    align-items: center;
  }
  .justify-center {
    justify-content: center;
  }
  .tx-table-text {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    line-height: 32px;
    font-size: 18px;
    cursor: pointer;
  }
  .table-activity {
    tbody {
      max-height: 375px !important;
    }
  }
  .euiTableHeaderButton-isSorted .euiTableSortIcon {
    display: none;
  }
  @media (max-width: 768px) {
    .tx-header-text {
      font-size: 16px;
    }

    .tx-flex-group {
      grid-gap: 0;
      gap: 0;
    }

    .tx-link-group {
      max-width: none;
    }

    .tx-flex-end {
      align-items: center;
      justify-content: space-between;
    }

    .tx-no-wrap {
      flex-wrap: nowrap;
    }

    .tx-list-totals {
      font-size: 16px;
    }

    .tx-total-group {
      grid-gap: 16px;
      gap: 16px;
    }

    .tx-flex-start {
      align-items: flex-start;
    }
  }
}

.card {
  .css-4ld6o8-euiCard__children {
    margin: 0;
  }
  .euiPanel {
    padding: 20px 16px 16px;
    min-height: 322px;
    max-height: 322px;
  }
  .extra-action {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }
  .accordion-group {
    .euiAccordion__buttonContent {
      font-size: 16px;
      font-weight: 500;
      font-family: "Inter", sans-serif;
    }
    .euiAccordion__children {
      padding: 1rem;
      p {
        line-height: 20px;
        font-size: 14px;
      }
    }
  }
  .card-content {
    min-height: 322px;
  }
  .action_title {
    .no-state-content {
      text-align: center;
    }
    .css-4ld6o8-euiCard__children {
      margin: 0;
      overflow: auto;
      height: 263px;
    }
  }
  .zero_state_action {
    p {
      font-size: 16px;
      font-weight: 600;
      font-family: "Inter", sans-serif;
    }
  }
  .zero-state-card {
    p {
      max-width: 75%;
      margin: auto;
      font-size: 14px;
      font-weight: 500;
      font-family: "Inter", sans-serif;
    }
  }
}

