.ai-main {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ai-plan {
  background-color: #f7f8fc;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
}

.ai-content-container {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.ai-side-nav-mobile {
  background-color: #fff;
  width: 273px;
  max-width: 273px;
  max-height: 100vh;
  display: none;
  padding: 8px 16px;
  text-align: center;
  .section-label {
    svg {
      inline-size: 16px !important;
      block-size: 16px !important;
      fill: #006bb8;
      margin-left: 8px;
    }
    &:hover {
      cursor: pointer;
    }
    .section-label-title {
      font-size: 18px;
      font-family: "Poppins";
      font-weight: 600;
      line-height: 20px;
      display: block;
    }
    .secondary-label-text {
      font-family: "Poppins", sans-serif;
      font-size: 14px !important;
      line-height: 32px !important;
      margin: 0;
      color: #69707d;
      font-weight: 400;
    }
  }
  .mobile-nav-container {
    max-width: 280px;
    min-width: 280px;
    max-height: 80vh;
    margin-top: 1rem;
    text-align: left;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    overflow-y: auto;
  }
}

.context-menu {
  width: 120px;
}

.ai-content {
  mix-blend-mode: normal;
  background-color: #fff;
  width: 100%;
  margin: 16px;
  padding: 16px;
  flex: 1;
  overflow-y: auto;
}

.ai-footer-nav {
  padding: 16px;
  background: #fff;
  border-top: 1px solid #d3dae6;
  height: 72px;
  flex-shrink: 0;
  .euiFlexGroup {
    flex-wrap: nowrap;
  }
  .euiFlexItem {
    flex: 0 1 auto;
  }
  .danger-sec-btn {
    color: #b4251d;
    border: 1px solid #b4251d;
  }
}

.ai-side-nav-desk {
  padding: 16px;
  background-color: #fff;
  width: 273px;
  max-width: 273px;
  display: block;
  overflow-y: scroll;
}

/* SIDE-BAR STYLES */

.side-bar-wrapper {
  .side-bar-header {
    h2 {
      font-family: "Poppins", sans-serif;
      font-weight: bold !important;
      font-size: 22px !important;
      line-height: 40px !important;
      margin: 0;
      margin-block-end: 0 !important;
    }
  }
  .timeline-wrapper {
    position: relative;
  }
  .progress-bar {
    position: absolute;
    left: 10px;
    width: 4px;
    background: #e3e3e3;
    z-index: 1;
  }
  .progress-bar-filled {
    background: #0073e6;
    width: 100%;
    transition: height 0.3s ease;
    z-index: 2;
  }
  .timeline {
    padding: 0;
    margin: 0;
    position: relative;
    z-index: 3;
    li {
      cursor: pointer;
      color: #69707d;
      .euiText {
        font-size: 16px;
        font-family: "Inter", sans-serif;
        line-height: 24px;
      }
    }
  }
  .step-number {
    background-color: #0073e6;
  }
  .step-number,
  .euiIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    inline-size: 24px;
    block-size: 24px !important;
    border-radius: 50%;
    color: #fff;
    font-family: "Inter", sans-serif !important;
    font-size: 14px !important;
    font-weight: 600;
    margin: 0;
  }
  .euiIcon {
    color: #d3dae6;
  }
  .section-item {
    display: flex;
    align-items: center;
    padding: 0;
    cursor: default;
  }
  .css-xiu9el-euiTimeline-xl
    [class*="euiTimelineItem-"]:not(:last-child)
    > [class*="euiTimelineItemIcon-"]::before {
    block-size: auto !important;
  }
  .progress-bar-container {
    position: absolute;
    left: 10px;
    width: 4px;
    background: #e3e3e3;
    z-index: 1;
    height: 100%;
  }
  .timeline-step {
    display: flex;
    align-items: center;
  }
  .active-section-label {
    color: #343741 !important;
  }
  .visited-step .euiText {
    font-weight: 600;
    color: #343741 !important;
  }
  .visited-step-icon circle {
    fill: #0073e6;
    transition: fill 0.3s ease;
  }
}

/* PLANBUILDER CONTENT STYLES */

.ai-flex-content {
  flex-wrap: nowrap !important;
  .euiFlexItem {
    flex: 1 1 auto;
    flex-basis: auto !important;
    inline-size: auto !important;
  }
}

.ai-content-title {
  line-height: 32px !important;
  font-size: 22px !important;
  font-weight: 600 !important;
  font-family: "Poppins", sans-serif;
  margin: 0;
  .euiFlexItem {
    flex: 0 1 auto;
    svg {
      inline-size: 18px !important;
      block-size: 18px !important;
      fill: #006bb8;
    }
  }
}

.ai-card {
  .css-4ld6o8-euiCard__children {
    margin-block-start: 0;
  }
  .ai-card-title {
    text-align: left;
    line-height: 32px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    font-family: "Poppins", sans-serif;
    margin-bottom: 16px;
  }
}

.ai-secondary-text {
  font-family: "Inter", sans-serif;
  font-size: 16px !important;
  line-height: 32px !important;
  margin: 0;
  font-weight: 400;
}

.flex-align {
  align-items: end;
}

.table-border {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  tr:last-child td {
    border-bottom: none;
  }
}

.table-text {
  .euiTableCellContent__text {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    line-height: 24px;
  }
}

.table-container {
  overflow-y: auto;
  width: 100%;

  @media (min-width: 983px) {
    max-width: 983px;
  }
}

.full-width-ai {
  max-width: 100% !important;
  .euiFormRow__fieldWrapper {
    max-width: 460px !important;
  }
}

/* REVIEW SECTION STYLES */

.completed-section-icon {
  fill: #00bfb3 !important;
}
.pencil-icon {
  border-radius: 0 !important;
  inline-size: 16px !important;
}

.header-style-review {
  font-weight: 600;
}

.content-row {
  .euiTableCellContent {
    padding-left: 16px;
  }
}

.stacked-table {
  max-height: 200px;
}

.review-table {
  .euiTableCellContent__text {
    font-size: 16px;
  }
  .euiTableCellContent {
    font-size: 16px;
    line-height: 24px;
  }
  .euiTableHeaderCell span {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
}

.expanded-table {
  thead {
    display: none;
  }
  .euiTableCellContent__text {
    font-size: 16px;
    font-weight: 600;
  }
}

.ai-table {
  .euiTableCellContent__text {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
}

.tabs-summary {
  .euiButtonGroupButton {
    background-color: #fbfcfd;
    border: 1px solid #cecece;
  }
  .euiButtonGroupButton-isSelected {
    background-color: #69707d;
  }
  span {
    font-size: 14px;
    font-family: "Inter", sans-serif;
  }
}

.no-wrap {
  flex-wrap: nowrap !important;
}

.align-l {
  align-items: flex-start !important;
}

.align-r {
  align-items: flex-end !important;
}

.flex-single {
  flex: 1 0 auto !important;
}

.mh-3 {
  min-height: 300px !important;
}

.custom-header {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.ai-header-content {
  font-weight: 600;
  font-size: 16px;
}

.ai-sub-header {
  padding-left: 26.5px;
  font-weight: 400;
}

.ai-sub-header-np {
  font-weight: 400;
}

.ai-sub-content {
  font-size: 16px;
}

/* MOBILE STYLES */

.mobile-content-style {
  font-size: 14px !important;
}

@media screen and (max-width: 991px) {
  .ai-side-nav-mobile {
    z-index: 999;
    background-color: #fff;
    width: 100%;
    max-width: none;
    height: 60px;
    max-height: 60px;
    display: block;
    position: fixed;
    .ai-mobile-nav-header {
      flex-wrap: nowrap;
      align-items: center;
      .ai-mobile-nav-item {
        flex: 1 1;
        svg {
          fill: #343741;
        }
      }
    }
  }

  .ai-content {
    margin-top: 76px;
  }

  .ai-side-nav-desk {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .ai-content {
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 1415px) {
  .ai-table {
    margin-left: 0;
    margin-right: 0;
  }
}

.eg-table {
  .euiTableCellContent {
    font-size: 16px;
    line-height: 24px;
    font-family: "Inter", sans-serif;
  }
  .euiTableHeaderCell span {
    font-size: 16px;
    line-height: 24px;
  }
  table {
    width: 100%;
    table-layout: auto;
    @media (max-width: 998px) {
      table-layout: fixed;
      .euiTableCellContent--truncateText {
        white-space: wrap !important;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .euiTableHeaderCell:first-child {
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        background-color: #fff;
        z-index: 2;
      }

      .euiTableHeaderCell:first-child:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 5px;
        background: linear-gradient(
          to right,
          rgba(206, 206, 206, 1),
          rgba(255, 255, 255, 0)
        );
      }

      .euiTableRow > .euiTableRowCell:first-child {
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        background-color: #fff;
        z-index: 2;
      }

      .euiTableRow > .euiTableRowCell:first-child:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 5px;
        background: linear-gradient(
          to right,
          rgba(206, 206, 206, 1),
          rgba(255, 255, 255, 0)
        );
      }
      th:nth-child(1),
      td:nth-child(1) {
        width: 200px;
      }
      th:nth-child(2),
      td:nth-child(2) {
        width: 200px;
      }
      th:nth-child(3),
      td:nth-child(3) {
        width: 200px;
      }
      th:nth-child(4),
      td:nth-child(4) {
        width: 200px;
      }
      th:nth-child(5),
      td:nth-child(5) {
        width: 200px;
      }
      th:nth-child(6),
      td:nth-child(6) {
        width: 260px;
      }
    }
  }
  .progress-section {
    gap: 10px !important;
    justify-content: center !important;
    align-items: center !important;
    .progress-bar {
      width: 174px;
      height: 8px;
    }
    .progress-text {
      align-items: flex-start;
      .euiText {
        font-size: 12px;
      }
    }
  }
  .drop-down-row {
    .cell-overflow {
      div {
        padding-left: 0px !important;
      }
    }
    .euiFlexGroup {
      gap: 16px;
    }
  }
  .child-row {
    background-color: #f7f8fc;
  }
  .cell-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .completed-link {
    font-size: 16px;
    font-family: "Inter", sans-serif;
    line-height: 24px;
  }
}
