.fp-context-menu {
  width: 128px !important;
}

.fp-general {
  .fp-header-text {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    line-height: 32px;
  }
}

.fp-page-zero {
  .fp-content {
    max-width: 90%;
    p {
      font-family: Inter, sans-serif;
      font-weight: 400;
      line-height: 24px;
      font-size: 16px;
      color: #000;
    }
  }
  .fp-card {
    width: 385px;
    height: 352px;
    border: 2px dashed #e5e5e5;
  }
}

.fp-page-list {
  width: 100%;
  .css-4ld6o8-euiCard__children {
    margin-block-start: 0px !important;
  }
  .fp-flex-group {
    align-items: center;
  }
  .fp-flex-end {
    align-items: flex-end;
  }
  .fp-list-desc {
    font-family: Inter, sans-serif;
    font-weight: 400;
    line-height: 24px;
    font-size: 16px;
    color: #000;
  }
  .fp-card-list-container {
    padding: 1rem 0;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .fp-card-list-container::-webkit-scrollbar {
    display: none;
  }
  .fp-card-list {
    flex: 0 0 auto;
    margin-right: 16px;
  }
  .fp-card-list:last-child {
    margin-right: 0;
  }
  .fp-card {
    width: 385px;
    height: 385px;
  }
  .fp-card-empty {
    position: relative;
    cursor: pointer;
    border: 2px dashed #e5e5e5;
  }
  .fp-card-empty .euiCard__title {
    display: none;
  }
  .fp-card-empty-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    svg {
      width: 54px;
      height: 54px;
      fill: #98a2b3;
    }
  }
  .fp-progress-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  @media (max-width: 989px) {
    .fp-card-list-container {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
}
