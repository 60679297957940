.nw-page {
  width: 100%;
  .container-fluid {
    padding-top: 0 !important;
  }
  .css-2pd10y {
    padding-top: 0 !important;
  }
  .nw-header-text {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    line-height: 32px;
  }
  .hc-desc-text {
    h1 {
      font-family: Poppins, sans-serif;
      font-weight: 600;
      line-height: 32px;
      font-size: 20px;
    }
  }
  .hc-desc {
    p {
      font-family: Inter, sans-serif;
      font-weight: 400;
      line-height: 24px;
      font-size: 16px;
    }
  }
  .euiTabs {
    max-inline-size: fit-content;
  }
  .nw-tab {
    span {
      font-family: Inter, sans-serif;
      font-size: 16px;
    }
  }
  .nw-secondary-text {
    p,
    ul {
      font-family: Inter, sans-serif;
      font-weight: 400;
      line-height: 20px;
      font-size: 16px;
      li {
        padding-bottom: 8px;
      }
    }
  }
  .nw-card {
    .css-4ld6o8-euiCard__children {
      margin-block-start: 0;
    }
    .nw-card-title {
      text-align: left;
      line-height: 32px !important;
      font-size: 20px !important;
      font-weight: 600 !important;
      font-family: "Poppins", sans-serif;
      margin-bottom: 16px;
    }
  }
  .nw-bar-chart {
    .recharts-cartesian-grid-vertical {
      line {
        display: none;
      }
    }
  }
  .euiTableRow-isExpandedRow {
    padding: 0;
  }
  img.euiIcon.euiButtonIcon__icon.css-e8xmlg-euiIcon-m-inherit {
    margin-block-end: 0;
  }
  thead {
    .euiTableCellContent__text {
      font-family: Inter, sans-serif;
      font-weight: 600;
      line-height: 20px;
      font-size: 16px;
    }
  }
  .total-row {
    .euiText {
      font-family: Inter, sans-serif;
      font-weight: 600;
      line-height: 24px;
      font-size: 16px;
    }
  }
  .nw-add-account-button {
    span {
      color: #343741;
    }
  }
  @media screen and (max-width: 768px) {
    .euiTabs {
      gap: 12px !important;
    }
    .hc-desc-text {
      h1 {
        font-size: 18px;
      }
    }
  }
}
