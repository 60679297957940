.acct-main {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}
.customPopoverPanel {
  padding: 0 !important;
}
.lp-sidebar {
  .lp-dropdown {
    color: #343741;
    font-size: 16px;
    line-height: 24px;
    font-family: "Inter", sans-serif;
    svg {
      fill: #343741;
      width: 16px;
    }
  }
  .lp-sidebar-item {
    font-size: 16px;
    line-height: 24px;
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .lp-sidebar-item:hover {
    background-color: #e6f1fa;
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .active {
    background-color: #e6f1fa;
    font-weight: 600;
    color: #006bb8;
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .lp-secondary-text {
    font-size: 14px;
    line-height: 24px;
  }
}
.table-accounts {
  .euiTableCellContent__text {
    font-size: 16px;
    line-height: 24px;
  }
}
.error-provider {
  background-color: #F8E9E9;
  color: #BD271E;
}

.error-provider .lp-sidebar-item-name,
.error-provider .lp-sidebar-item-accounts {
  color: #BD271E;
  font-weight: 600;
}

.error-provider:hover {
  background-color: #F8E9E9 !important;
  color: #BD271E;
}

.error-provider.active {
  background-color: #F8E9E9;
  color: #BD271E;
}
