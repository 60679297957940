.acct-page {
  width: 100%;
  .acct-header-text {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    line-height: 32px;
  }
  .acct-flex-group {
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .acct-link-group {
    max-width: 450px;
    display: flex;
    flex-wrap: nowrap;
  }
  .acct-flex-end {
    align-items: flex-end;
  }
  .align-center {
    align-items: center;
  }
  .justify-center {
    justify-content: center;
  }
  .acct-list-header {
    font-family: Inter, sans-serif;
    font-weight: 400;
    line-height: 20px;
    font-size: 14px;
  }
  .acct-list-totals {
    font-family: Inter, sans-serif;
    font-weight: bold;
    line-height: 32px;
    font-size: 27px;
  }
  .euiTabs {
    max-inline-size: fit-content;
  }
  .acct-tab {
    span {
      font-family: Inter, sans-serif;
      font-size: 16px;
    }
  }
  .table-styles {
    tbody {
      font-size: 16px;
      line-height: 24px;
    }
    th {
      .euiTableCellContent__text {
        font-family: Inter, sans-serif;
        line-height: 24px;
        font-size: 16px;
      }
    }
    .euiTableRow-isExpandedRow {
      .euiTableCellContent {
        padding: 0;
      }
    }
    .expanded-rows {
      th .euiTableCellContent {
        padding: 8px !important;
        background-color: #fafbfd;
      }
      .euiTableRowCell {
        padding: 8px !important;
        background-color: #fafbfd;
      }
    }
  }
  .acct-total-group {
    gap: 100px;
  }

  @media (max-width: 768px) {
    .acct-flex-group {
      gap: 0;
    }
    .acct-link-group {
      max-width: none;
    }
    .acct-flex-end {
      align-items: center;
      justify-content: space-between;
    }
    .acct-no-wrap {
      flex-wrap: nowrap;
    }
    .acct-list-totals {
      font-size: 16px;
    }
    .acct-total-group {
      gap: 16px;
    }
    .acct-flex-start {
      align-items: flex-start;
    }
  }
}
