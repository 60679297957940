.tx-page {
  width: 100%;
  .acct-header-text {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    line-height: 32px;
  }
  .tx-flex-group {
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .tx-link-group {
    width: 450px;
    button {
      font-size: 16px;
    }
  }
  .tx-flex-end {
    align-items: flex-end;
  }
  .align-center {
    align-items: center;
  }
  .justify-center {
    justify-content: center;
  }
  .tx-table-text {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    line-height: 32px;
    font-size: 18px;
    cursor: pointer;
  }
  .table-activity {
    tbody {
      max-height: 375px !important;
    }
  }
  .euiTableHeaderButton-isSorted .euiTableSortIcon {
    display: none;
  }  

  @media (max-width: 768px) {
    .tx-header-text {
      font-size: 16px;
    }
    .tx-flex-group {
      gap: 0;
    }
    .tx-link-group {
      max-width: none;
    }
    .tx-flex-end {
      align-items: center;
      justify-content: space-between;
    }
    .tx-no-wrap {
      flex-wrap: nowrap;
    }
    .tx-list-totals {
      font-size: 16px;
    }
    .tx-total-group {
      gap: 16px;
    }
    .tx-flex-start {
      align-items: flex-start;
    }
  }
}
